import "./PublishedItem.css";
import { useState } from "react";
import { Divider, Flex, Space, Button, Dropdown, message, Modal, Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HeartOutlined, CommentOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { removePost } from "../../../../../../services/supabaseClient";
import { useAuth } from "../../../../../../contexts/AuthContext";
import useWindowSize from "../../../../../../hooks/useWindowSize";
const { confirm } = Modal;

const guestItems = [
  {
    label: "Detail",
    key: "detail",
  },
];

const ownerItems = [
  {
    label: "Detail",
    key: "detail",
  },
  {
    label: "Delete",
    key: "delete",
  },
];

const PublishedItem = ({ post, onPostChange }) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onClick = ({ key }) => {
    if (key === "detail") {
      navigate(`/home/${post.id}`);
    } else if (key === "delete") showDeleteConfirm(post.id);
  };

  const onDelete = async (id) => {
    try {
      setIsSubmiting(true);
      const { error } = await removePost(id);

      if (error) {
        message.error(error.message);
      } else {
        message.success("Post was deleted successfully!");
        onPostChange();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  const showDeleteConfirm = (id) => {
    return confirm({
      title: "Confirm delete post",
      content: "Are you sure you want to delete this post?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onDelete(id);
      },
    });
  };

  return (
    <Flex gap={12} vertical className="vfra-published-item-container">
      {post.media_data && post.media_data.urls && post.media_data.urls.length > 1 && (
        <Carousel slidesToShow={1} slidesToScroll={1} dotPosition="bottom" arrows infinite={false}>
          {post.media_data.urls.map((item, index) => (
            <Flex
              key={index}
              justify="center"
              align="center"
              onClick={() => navigate(`/home/${post.id}`)}
              style={{ cursor: "pointer" }}>
              <img
                alt=""
                style={{ padding: !isMobileView ? "18px 0" : "12px 0", cursor: "pointer" }}
                width="60%"
                src={item}
              />
            </Flex>
          ))}
        </Carousel>
      )}

      {post.media_data && post.media_data.urls && post.media_data.urls.length === 1 && (
        <Flex justify="center" onClick={() => navigate(`/home/${post.id}`)} style={{ cursor: "pointer" }}>
          <img
            style={{ padding: "12px 0" }}
            alt=""
            width="60%"
            src={post.media_data ? `${post.media_data.urls[0]}` : ""}
          />
        </Flex>
      )}

      <span title={post.caption} className="two-line-ellipsis" style={{ fontSize: "0.7rem" }}>
        {post.caption}
      </span>

      <Flex justify="space-between">
        <Space size="small" split={<Divider type="vertical" />} align="center">
          <Space className="stat-color">
            <HeartOutlined />
            {post.total_hearts ?? 0}
          </Space>
          <Space className="stat-color">
            <CommentOutlined />
            {post.total_comments ?? 0}
          </Space>
          <Space className="stat-color">
            <EyeOutlined />
            {post.total_vieweds ?? 0}
          </Space>
        </Space>
        <Dropdown
          menu={{
            items: isYourself ? ownerItems : guestItems,
            onClick,
          }}
          placement="bottomLeft"
          trigger={["click"]}>
          <Button loading={isSubmiting} size="small" type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      </Flex>
    </Flex>
  );
};

export default PublishedItem;
