import "./HeaderLayout.css";
import logoNotext from "../../assets/logo-notext.png";
import logoLandscape from "../../assets/logo-landscape.png";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Avatar, Flex, Dropdown, message, Button, Row, Col, Drawer } from "antd";
import { UserOutlined, VideoCameraAddOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { supabase } from "../../services/supabaseClient";
import { useAuth } from "../../contexts/AuthContext";
import useWindowSize from "../../hooks/useWindowSize";

const items = [
  {
    label: "Profile",
    key: "1",
  },
  {
    label: "Logout",
    key: "2",
  },
];

const HeaderLayout = () => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let currentPage = "/";
  let currentMenu = "";
  if (pathname.includes("/home")) {
    currentPage = "/home";
    currentMenu = "Explore";
  } else if (pathname.includes("/perfume")) {
    currentPage = "/perfume";
    currentMenu = "Perfume";
  } else if (pathname.includes("/brand")) {
    currentPage = "/brand";
    currentMenu = "Brand";
  } else {
    currentPage = "/profile";
    currentMenu = "Profile";
  }

  const { user, openLoginModal } = useAuth();

  const onClick = ({ key }) => {
    if (key === "1") navigate(`/profile/${user.user_id}`);
    else if (key === "2") signOut();
  };

  async function signOut() {
    const { error } = await supabase.auth.signOut();

    if (error) {
      message.error(error.message);
    }
  }

  // mobile menu
  const [open, setOpen] = useState(false);

  const showMobileMenu = () => {
    setOpen(true);
  };

  const onCloseMobileMenu = () => {
    setOpen(false);
  };

  const handleClickMobileMenu = (menu) => {
    setOpen(false);
    navigate(menu);
  };

  if (isMobileView) {
    return (
      <>
        <Flex justify="space-between" align="center" className="vfra-header-container">
          <Button onClick={showMobileMenu} type="text" shape="circle" icon={<MenuOutlined />} />

          <Flex gap={6} justify="center" align="center">
            <span>{currentMenu}</span>
            {currentPage === "/home" && (
              <Button
                size="small"
                title="create post"
                onClick={() => navigate(`/profile/${user.user_id}`, { state: { type: "create-post" } })}
                shape="circle"
                icon={<VideoCameraAddOutlined />}></Button>
            )}
          </Flex>

          {user && (
            <Flex justify="end" align="center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                placement="bottomLeft"
                trigger={["click"]}>
                <Flex justify="end" align="center">
                  <Avatar src={user.vfra_user?.avatar ?? user.avatar_url} />
                </Flex>
              </Dropdown>
            </Flex>
          )}

          {!user && (
            <Flex justify="end" align="center" style={{ padding: "6px 0" }}>
              <Avatar onClick={openLoginModal} icon={<UserOutlined />} />
            </Flex>
          )}
        </Flex>

        <Drawer
          getContainer={false}
          title={
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Avatar onClick={() => navigate("/home")} src={<img src={logoNotext} alt="" />} />
                <b style={{ marginLeft: "6px" }}>Vfra</b>
              </Flex>

              <Button onClick={onCloseMobileMenu} type="text" shape="circle" icon={<CloseOutlined />} />
            </Flex>
          }
          placement="left"
          open={open}
          closable={false}
          onClose={onCloseMobileMenu}
          width="70%">
          <p
            className={currentPage === "/home" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/home")}>
            Explore
          </p>
          <p
            className={currentPage === "/perfume" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/perfume")}>
            Perfume
          </p>
          <p
            className={currentPage === "/brand" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/brand")}>
            Brands
          </p>
        </Drawer>
      </>
    );
  } else {
    return (
      <Row className="vfra-header-container">
        <Col span={6}>
          <Flex align="center" style={{ height: "100%" }}>
            <Avatar
              style={{ width: "128px" }}
              onClick={() => navigate("/home")}
              src={<img src={logoLandscape} alt="" />}
            />
          </Flex>
        </Col>

        <Col span={12} justify="center">
          <Menu
            className="vfra-menu-style"
            theme="light"
            mode="horizontal"
            selectedKeys={currentPage}
            items={[
              {
                key: "/home",
                label: "Explore",
                onClick: () => {
                  navigate("/");
                },
              },
              {
                key: "/perfume",
                label: "Perfumes",
                onClick: () => {
                  navigate("/perfume");
                },
              },
              {
                key: "/brand",
                label: "Brands",
                onClick: () => {
                  navigate("/brand");
                },
              },
            ]}
          />
        </Col>

        <Col span={6} justify="end">
          {user && (
            <Flex justify="end" align="center" gap={12}>
              {currentPage === "/home" && (
                <Button
                  title="create post"
                  onClick={() => navigate(`/profile/${user.user_id}`, { state: { type: "create-post" } })}
                  shape="round"
                  icon={<VideoCameraAddOutlined />}>
                  Create
                </Button>
              )}
              <Flex align="center">
                <h4 className="one-line-ellipsis" style={{ marginRight: "6px" }}>
                  {user.vfra_user?.name ?? user.name}
                </h4>
                <Dropdown
                  menu={{
                    items,
                    onClick,
                  }}
                  placement="bottomLeft"
                  trigger={["click"]}>
                  <Flex justify="end" align="center">
                    <Avatar size="large" src={user.vfra_user?.avatar ?? user.avatar_url} />
                  </Flex>
                </Dropdown>
              </Flex>
            </Flex>
          )}

          {!user && (
            <Flex justify="end" align="center" style={{ padding: "6px 0" }}>
              <Avatar size="large" onClick={openLoginModal} icon={<UserOutlined />} />
            </Flex>
          )}
        </Col>
      </Row>
    );
  }
};

export default HeaderLayout;
