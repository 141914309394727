import "../Profile.css";
import React, { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LikeOutlined, HeartOutlined } from "@ant-design/icons";
import { Avatar, List, Space, Flex, Typography, message, Skeleton, Button } from "antd";
import { getCommentsByUser } from "../../../services/supabaseClient";
import { formatISOToUTC7, countReactions } from "../../../utils/service";
import useWindowSize from "../../../hooks/useWindowSize";
const PAGE_SIZE = 10;

const { Paragraph } = Typography;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const Reviews = () => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [showLoadmore, setShowLoadmore] = useState(true);

  const fetchData = useCallback(
    async (startTime = 0) => {
      try {
        const { error, data } = await getCommentsByUser(userId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            const convertedData = data.map((e) => {
              const { reaction_count: likes_count, active_for_user: active_for_user_like } = countReactions(
                e.reactions_metadata,
                "like"
              );
              const { reaction_count: hearts_count, active_for_user: active_for_user_heart } = countReactions(
                e.reactions_metadata,
                "heart"
              );
              const created_at = formatISOToUTC7(e.created_at);
              return {
                id: e.id,
                user_id: e.user_id,
                comment: e.comment,
                likes_count,
                active_for_user_like,
                hearts_count,
                active_for_user_heart,
                replies_count: e.replies_count,
                user_name: e.user.name,
                user_avatar: e.user.avatar,
                created_at,
                perfume_id: e.perfume?.id,
                perfume_name: e.perfume?.name,
                perfume_image_url: e.perfume?.image_url,
              };
            });

            if (startTime !== 0) {
              setComments((prev) => [...prev, ...convertedData]);
            } else {
              setComments(convertedData);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadmore = () => {
    const startTime = comments.length > 0 ? comments[comments.length - 1].created_at : 0;
    fetchData(startTime);
  };

  const imageWidth = isMobileView ? 50 : 100;

  return (
    <>
      {isLoading &&
        Array.from({
          length: 5,
        }).map((_, i) => (
          <Skeleton
            key={i}
            avatar
            paragraph={{
              rows: 2,
            }}
          />
        ))}

      {!isLoading && (
        <List
          itemLayout="vertical"
          size="large"
          dataSource={comments}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              actions={[
                <IconText icon={HeartOutlined} text={item.hearts_count} key="list-vertical-heart-o" />,
                <IconText icon={LikeOutlined} text={item.likes_count} key="list-vertical-like-o" />,
              ]}>
              <Flex gap={isMobileView ? 2 : 16}>
                <div style={{ width: "100%" }}>
                  <List.Item.Meta
                    avatar={<Avatar alt="" src={item.user_avatar} />}
                    title={
                      <Flex justify="space-between" align="center">
                        <Flex vertical="vertical">
                          <span style={{ fontWeight: "700" }}>{item.user_name}</span>
                          <span
                            style={{ fontWeight: "normal", fontSize: "0.75rem", color: "rgba(113,128,150), 0.75)" }}>
                            {item.created_at}
                          </span>
                        </Flex>
                      </Flex>
                    }
                  />
                  <Paragraph ellipsis={{ rows: isMobileView ? 3 : 5, expandable: true }}>{item.comment}</Paragraph>
                </div>

                {item.perfume_image_url && (
                  <Flex vertical align="center" justify="center">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/product/${item.perfume_id}`)}
                      alt=""
                      src={item.perfume_image_url}
                      width={imageWidth}
                    />
                  </Flex>
                )}
              </Flex>
            </List.Item>
          )}
        />
      )}

      {showLoadmore && (
        <Flex justify="center" style={{ marginTop: "12px" }}>
          <Button onClick={handleLoadmore} disabled={isLoading} size="small">
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
};
export default Reviews;
