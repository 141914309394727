import { useState } from "react";
import { Flex } from "antd";
import PopularBrands from "./PopularBrands/PopularBrands";
import SearchBrands from "./SearchBrands/SearchBrands";
import DebouncedInput from "../../components/DebouncedInput";

const Brands = () => {
  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    if (!value) {
      setSearchMode(false);
    } else {
      setSearchMode(true);
    }

    setSearchValue(value);
  };

  return (
    <>
      <Flex justify="center" align="center" className="vfra-margin-top vfra-padding-top">
        <DebouncedInput className="vfra-search" onDebouncedChange={handleChange} loading={loading} />
      </Flex>

      {!searchMode && <PopularBrands />}
      {searchMode && <SearchBrands onLoadingChange={(value) => setLoading(value)} searchValue={searchValue} />}
    </>
  );
};

export default Brands;
