import { useEffect, useCallback, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Flex, message, Spin } from "antd";
import { getFeeds, markViewedPost, getPostDetails } from "../../services/supabaseClient";
import { useAuth } from "../../contexts/AuthContext";
import Feed from "./Feed/Feed";
import "./Home.css";
const PAGE_SIZE_DEFAULT = 5;
const PAGE_SIZE_GUEST = 50;

const reactionActions = {
  add: "add",
  remove: "remove",
};
const reactionTypes = {
  save: "save",
  heart: "heart",
};

const Home = () => {
  window.scrollTo(0, 0);
  const { postId } = useParams();

  const { user, openLoginModal } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [isEnding, setIsEnding] = useState(false);

  // handle group viewed
  const viewTimesRef = useRef([]);

  const postRefs = useRef([]);
  const enterTimes = useRef({});

  const onMarkViewedPosts = () => {
    const unsentPosts = viewTimesRef.current.filter((e) => e.timeChanged && (e.isFirstTime || e.timeSpent > 1000));

    try {
      const payload = unsentPosts.map((e) => {
        return {
          pid: e.id,
          s: e.startTime,
          e: e.startTime + e.timeSpent,
        };
      });
      markViewedPost(payload);

      viewTimesRef.current = viewTimesRef.current.map((v) => {
        return {
          ...v,
          timeChanged: false,
        };
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = useCallback(
    async (isLoadmore = false, pageSize = PAGE_SIZE_DEFAULT) => {
      try {
        let res = null;
        if (!postId) {
          res = await getFeeds(pageSize);
        } else {
          res = await getPostDetails([postId]);
        }

        const { error, data } = res;
        if (error) {
          message.error(error.message);
        } else {
          if (data && data.length > 0) {
            setIsEnding(false);
            if (isLoadmore) {
              setPosts((prev) => [...prev, ...data]);
            } else setPosts(data);
          } else {
            setIsEnding(true);
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [postId]
  );

  useEffect(() => {
    if (user) {
      fetchData();
    } else {
      fetchData(false, PAGE_SIZE_GUEST);
    }
  }, [fetchData, user]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const postIndex = parseInt(entry.target.getAttribute("data-index"), 10);
          const postId = posts[postIndex].post.id;

          if (entry.isIntersecting) {
            if (user && posts.length - postIndex < 2) {
              onMarkViewedPosts();
              fetchData(true, PAGE_SIZE_DEFAULT - posts.length + postIndex);
            }

            // Record the time when the post enters the viewport
            enterTimes.current[postIndex] = new Date().getTime();
          } else {
            // Calculate the time spent viewing when the post exits the viewport
            const exitTime = new Date().getTime();
            const enterTime = enterTimes.current[postIndex];

            if (enterTime) {
              const timeSpent = exitTime - enterTime; // in miliseconds

              const viewTimes = viewTimesRef.current;
              const index = viewTimes.findIndex((e) => e.id === postId);
              viewTimes.push({
                id: postId,
                startTime: enterTime,
                timeSpent: timeSpent,
                isFirstTime: index >= 0 ? false : true,
                timeChanged: true,
              });

              delete enterTimes.current[postIndex];
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    const currentPostRefs = postRefs.current;

    currentPostRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentPostRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [posts, fetchData, user]);

  // handle reactions change
  const handleReactionsChange = (id, index, action, type) => {
    if (posts[index].post.id === id) {
      if (type === reactionTypes.heart) {
        if (action === reactionActions.add) {
          posts[index].post.total_hearts++;
          posts[index].post.active_for_user_heart = true;
        } else if (action === reactionActions.remove) {
          posts[index].post.total_hearts--;
          posts[index].post.active_for_user_heart = false;
        }
      } else if (type === reactionTypes.save) {
        if (action === reactionActions.add) {
          posts[index].post.total_saves++;
          posts[index].post.active_for_user_save = true;
        } else if (action === reactionActions.remove) {
          posts[index].post.total_saves--;
          posts[index].post.active_for_user_save = false;
        }
      }
    }

    setPosts([...posts]);
  };

  return (
    <>
      {!isLoading && !postId && posts.length > 0 && (
        <div className="vfra-infinite-view">
          {posts.map((post, index) => (
            <Feed
              key={post.post.id}
              post={post.post}
              ref={(el) => (postRefs.current[index] = el)}
              index={index}
              onChangeReaction={handleReactionsChange}
            />
          ))}

          {isEnding && (
            <Flex justify="center" style={{ marginTop: "-32px" }}>
              <span>Nothing new!</span>
            </Flex>
          )}

          {!user && (
            <Flex align="center" justify="center" style={{ marginTop: "-36px" }}>
              <span>
                Please{" "}
                <Button size="small" shape="round" onClick={() => openLoginModal()}>
                  Sign in
                </Button>{" "}
                to explore more!
              </span>
            </Flex>
          )}
        </div>
      )}

      {!isLoading && postId && posts.length > 0 && (
        <div className="vfra-infinite-view">
          {posts.map((post) => (
            <Feed key={post.post.id} post={post.post} />
          ))}
        </div>
      )}

      {isLoading && (
        <Flex justify="center" style={{ paddingTop: "20%" }}>
          <Spin />
        </Flex>
      )}

      {isEnding && posts.length === 0 && (
        <Flex justify="center" style={{ paddingTop: "20%" }}>
          <i>There are no posts, please visit later!</i>
        </Flex>
      )}
    </>
  );
};

export default Home;
