import { useState } from "react";
import { Flex, Button, message, Tag, Modal } from "antd";
import { PlusOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { follow, unfollow } from "../../../../services/supabaseClient";
import { useAuth } from "../../../../contexts/AuthContext";
const { confirm } = Modal;

const followStyle = {
  fontSize: "0.8rem",
  color: "rgb(96, 96, 96)",
};

const followCountStyle = { fontWeight: "600", fontSize: "1rem" };

const Follow = ({ vfraUser, isYourself }) => {
  const { refreshVfraUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFollow = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        followee_id: vfraUser.id,
      };

      const { error } = await follow(payload);
      if (error) {
        message.error(error.message);
      } else {
        message.success("Follow successfuly!");
        refreshVfraUser();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUnfollow = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        followee_id: vfraUser.id,
      };

      const { error } = await unfollow(payload);
      if (error) {
        message.error(error.message);
      } else {
        message.success("Unfollow successfuly!");
        refreshVfraUser();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showUnfollowConfirm = (id) => {
    if (isSubmitting) {
      return;
    }

    return confirm({
      title: "Confirm unfollow",
      content: `Are you sure you want to unfollow ${vfraUser.name} ?`,
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onUnfollow(id);
      },
    });
  };

  return (
    <Flex justify="center" gap={12} vertical align="center" style={{ marginTop: "6px" }}>
      {!isYourself && !vfraUser.follow_detail.followed && (
        <Button
          size="small"
          type="primary"
          shape="round"
          style={{ fontSize: "0.8rem", padding: "1px 18px" }}
          icon={<PlusOutlined />}
          loading={isSubmitting}
          onClick={onFollow}>
          Follow
        </Button>
      )}

      {!isYourself && vfraUser.follow_detail.followed && (
        <Flex gap={12} align="center">
          <Tag color="processing" bordered={false} style={{ fontSize: "0.8rem", padding: "1px 18px" }}>
            Following
          </Tag>
          <Button
            loading={isSubmitting}
            onClick={showUnfollowConfirm}
            size="small"
            shape="circle"
            icon={<UserDeleteOutlined />}
          />
        </Flex>
      )}

      <Flex justify="center" gap={24}>
        <span style={followStyle}>
          <span style={followCountStyle}>{vfraUser && vfraUser.follow_detail.total_followers}</span> followers
        </span>
        <span style={followStyle}>
          <span style={followCountStyle}>{vfraUser && vfraUser.follow_detail.total_followees}</span> following
        </span>
      </Flex>
    </Flex>
  );
};

export default Follow;
