import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Divider, List, message, Skeleton, Flex, Button } from "antd";
import { getCommentsByTopic } from "../../../services/supabaseClient";
import {
  formatISOToUTC7,
  countReactions,
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  REMOVE_REACTION_ACTION,
} from "../../../utils/service";
import Comment from "./Comment";
import CommentArea from "./CommentArea";

const PAGE_SIZE = 10;

const Comments = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(true);
  const [dataComments, setDataComments] = useState([]);

  const fetchDataComments = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime === 0) {
          setLoading(true);
        } else {
          setIsLoadmore(true);
        }

        const { data, error } = await getCommentsByTopic(productId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            setShowLoadmore(true);

            const convertedData = data.map((e) => {
              const { reaction_count: likes_count, active_for_user: active_for_user_like } = countReactions(
                e.reactions_metadata,
                "like"
              );
              const { reaction_count: hearts_count, active_for_user: active_for_user_heart } = countReactions(
                e.reactions_metadata,
                "heart"
              );
              const created_at = formatISOToUTC7(e.created_at);
              return {
                id: e.id,
                user_id: e.user_id,
                comment: e.comment,
                likes_count,
                active_for_user_like,
                hearts_count,
                active_for_user_heart,
                replies_count: e.replies_count,
                user_name: e.user.name,
                user_avatar: e.user.avatar,
                created_at,
              };
            });

            setDataComments((prev) => {
              if (startTime !== 0) {
                return [...prev, ...convertedData];
              }

              return convertedData;
            });
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime === 0) {
          setLoading(false);
        } else {
          setIsLoadmore(false);
        }
      }
    },
    [productId]
  );

  useEffect(() => {
    fetchDataComments();
  }, [productId, fetchDataComments]);

  const handleLoadMore = () => {
    const startTime = dataComments.length > 0 ? dataComments[dataComments.length - 1].created_at : 0;
    fetchDataComments(startTime);
  };

  const handleAddComment = () => {
    fetchDataComments();
  };

  const handleRemoveComment = (id) => {
    const dataAfterRemove = dataComments.filter((c) => c.id !== id);
    setDataComments(dataAfterRemove);
  };

  const handleChangeCommentReaction = (commentId, reactionType, action) => {
    for (let i = 0; i < dataComments.length; i++) {
      if (dataComments[i].id === commentId) {
        if (reactionType === HEART_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].hearts_count++;
            dataComments[i].active_for_user_heart = true;
          } else if (action === REMOVE_REACTION_ACTION) {
            dataComments[i].hearts_count--;
            dataComments[i].active_for_user_heart = false;
          }
        } else if (reactionType === LIKE_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].likes_count++;
            dataComments[i].active_for_user_like = true;
          } else if (action === REMOVE_REACTION_ACTION) {
            dataComments[i].likes_count--;
            dataComments[i].active_for_user_like = false;
          }
        }
      }
    }

    setDataComments([...dataComments]);
  };

  return (
    <>
      <Divider style={{ fontWeight: "bold" }}>All reviews</Divider>
      <CommentArea onAddComment={handleAddComment} />
      <Divider></Divider>

      {!loading && dataComments.length === 0 && (
        <Flex align="center" justify="center">
          <p style={{ color: "rgb(100 100 100)", fontSize: "0.825rem" }}>Write first review for this perfume!</p>
        </Flex>
      )}

      {loading && (
        <div>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
        </div>
      )}

      {!loading && dataComments.length > 0 && (
        <List
          itemLayout="vertical"
          size="large"
          className="vfra-comments"
          dataSource={dataComments}
          renderItem={(item) => (
            <Comment
              key={item.id}
              item={item}
              onRemoveComment={handleRemoveComment}
              onChangeCommentReaction={handleChangeCommentReaction}
            />
          )}
        />
      )}

      {!loading && showLoadmore && (
        <Flex justify="center" style={{ marginTop: "12px" }}>
          <Button onClick={handleLoadMore} size="small" loading={isLoadmore}>
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
};

export default Comments;
