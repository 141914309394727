import { useNavigate } from "react-router-dom";
import { Flex, Card, Badge, Rate } from "antd";
import useWindowSize from "../../../../hooks/useWindowSize";

const cardStyle = { width: "100%", border: "1px solid #E2E2E2" };

const Perfume = ({ item }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      style={cardStyle}
      bordered={false}
      onClick={() => navigate(`/product/${item.pid}`)}
      styles={{ body: { padding: isMobileView ? "6px" : "12px" } }}>
      <Flex justify="start" align="center">
        <img alt={item.perfume_name} width="70px" src={item.image_url} style={{ marginRight: "12px" }} />
        <Flex vertical={isMobileView} justify="space-between" style={{ width: "100%" }}>
          <span style={{ fontWeight: "700", marginBottom: isMobileView ? "12px" : "0px" }}>
            {item.perfume_name ?? item.name}
          </span>

          <Flex align="center">
            <Badge
              count={item.rating}
              style={{
                backgroundColor: "rgb(229 229 229 / 37%)",
                color: "rgba(0, 0, 0, 0.88)",
                fontWeight: "700",
                marginRight: "12px",
              }}
            />
            <Rate disabled allowHalf defaultValue={item.rating} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Perfume;
