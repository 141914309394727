import { popular_perfumes } from "../../../utils/data";
import { Divider, Carousel } from "antd";
import CardPerfume from "./CardPerfume";
import useWindowSize from "../../../hooks/useWindowSize";

const PopularPerfumes = () => {
  const { isMobileView } = useWindowSize();

  return (
    <>
      <Divider style={{ marginBottom: "4.5rem" }}>Popular Perfumes</Divider>
      <Carousel
        slidesToShow={isMobileView ? 1 : 4}
        slidesToScroll={isMobileView ? 1 : 4}
        dotPosition="bottom"
        infinite
        draggable
        arrows
        autoplay>
        {popular_perfumes.map((item) => (
          <CardPerfume key={item.id} item={item} />
        ))}
      </Carousel>
    </>
  );
};

export default PopularPerfumes;
