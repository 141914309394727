import "./ImageDragger.css";
import { useState } from "react";
import { Button, Upload, message, Row, Col, Flex } from "antd";
import { FileImageTwoTone, PlusOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../../../utils/service";
import useWindowSize from "../../../../../hooks/useWindowSize";
const { Dragger } = Upload;

const ImageDragger = ({ imageList, onImageChange }) => {
  const { isMobileView } = useWindowSize();
  const [selectedImage, setSelectedImage] = useState(null);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return false;
  };

  const props = {
    multiple: true,
    maxCount: 5,
    fileList: imageList,
    beforeUpload,
    async onChange({ fileList }) {
      for (let file of fileList) {
        file.previewSrc = await getBase64(file.originFileObj);
      }

      if (!selectedImage) setSelectedImage(fileList[0]);
      if (fileList.length === 0) setSelectedImage(null);
      onImageChange(fileList);
    },
    itemRender() {
      return null;
    },
  };

  const handleSelectImage = (e, image) => {
    e.stopPropagation();

    if (selectedImage && selectedImage.uid === image.uid) return;
    setSelectedImage(image);
  };

  const handleRemoveImage = (e, imageId) => {
    e.stopPropagation();
    const newImageList = imageList.filter((image) => image.uid !== imageId);

    if (selectedImage.uid === imageId) {
      setSelectedImage(newImageList[0]);
    }

    onImageChange(newImageList);
  };

  return (
    <Dragger {...props} className="vfra-image-dragger-container">
      {imageList.length === 0 && (
        <>
          <p className="ant-upload-drag-icon">
            <FileImageTwoTone style={{ fontSize: "3rem" }} />
          </p>
          <p className="ant-upload-text" style={{ fontSize: "0.9rem" }}>
            Click or drag up to 5 images
          </p>
        </>
      )}

      {imageList.length > 0 && (
        <Row gutter={[12, 12]}>
          <Col span={4}>
            <Flex vertical gap={12} justify="center" align="center" style={{ height: "100%", overflow: "scroll" }}>
              {imageList.map((image) => (
                <div
                  key={image.uid}
                  style={{
                    display: "inline-block",
                    border: `2px solid ${image.uid === selectedImage.uid ? "#1677ff" : "transparent"}`,
                  }}
                  onClick={(e) => handleSelectImage(e, image)}>
                  <img alt="" width={isMobileView ? "20px" : "45px"} src={image.previewSrc} />
                </div>
              ))}

              {imageList.length < 5 && <Button shape="circle" icon={<PlusOutlined />} />}
            </Flex>
          </Col>

          <Col span={20}>
            <Flex gap={12} vertical justify="center">
              <div>
                <img
                  alt=""
                  height={isMobileView ? "230px" : "370px"}
                  src={selectedImage.previewSrc}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>

              <div>
                <Button onClick={(e) => handleRemoveImage(e, selectedImage.uid)} size="small" type="text" shape="round">
                  Remove
                </Button>
              </div>
            </Flex>
          </Col>
        </Row>
      )}
    </Dragger>
  );
};

export default ImageDragger;
