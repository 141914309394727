import "./Community.css";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { Flex, Button } from "antd";
import CreatePost from "./CreatePost/CreatePost";
import ManagePost from "./ManagePost/ManagePost";

const Community = () => {
  const { user } = useAuth();
  const { userId } = useParams();

  const location = useLocation();
  const { type } = location.state || {};

  const isYourself = user && user.user_id === userId;
  const [showCreatePost, setShowCreatePost] = useState(type === "create-post");
  const [reloadPosts, setReloadPosts] = useState(false);

  return (
    <>
      {isYourself && (
        <div className="vfra-community-container">
          {showCreatePost && (
            <CreatePost onClose={() => setShowCreatePost(false)} onCreate={() => setReloadPosts((prev) => !prev)} />
          )}

          {!showCreatePost && (
            <Flex justify="center">
              <Button onClick={() => setShowCreatePost(true)} shape="round">
                Create a new post
              </Button>
            </Flex>
          )}
        </div>
      )}
      <ManagePost reloadPosts={reloadPosts} />
    </>
  );
};
export default Community;
