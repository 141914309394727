import { useNavigate } from "react-router-dom";
import { Card, Flex } from "antd";

const cardSearchStyle = {
  height: "90px",
};

const cardBodyStyle = {
  padding: "8px",
};

const Perfume = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      style={cardSearchStyle}
      bordered={false}
      onClick={() => navigate(`/product/${item.pid}`)}
      styles={{ body: cardBodyStyle }}>
      <Flex justify="start" align="center">
        <img alt="" width="50px" src={item.image_url} style={{ marginRight: "8px" }} />
        <Flex vertical>
          <span style={{ fontWeight: "700" }} className="one-line-ellipsis">
            {item.name}
          </span>
          <span className="one-line-ellipsis" style={{ fontSize: "0.8rem" }}>
            {item.brand_name}
          </span>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Perfume;
