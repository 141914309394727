import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Flex, List, Button, message, Typography, Modal } from "antd";
import { LikeOutlined, HeartOutlined, DeleteOutlined, LikeFilled, HeartFilled } from "@ant-design/icons";
import { deleteComment, addCommentReaction, removeCommentReaction } from "../../../services/supabaseClient";
import { useAuth } from "../../../contexts/AuthContext";
import { HEART_REACTION, LIKE_REACTION, ADD_REACTION_ACTION, REMOVE_REACTION_ACTION } from "../../../utils/service";

const { Paragraph } = Typography;
const { confirm } = Modal;

const Comment = forwardRef(({ item, onRemoveComment, onChangeCommentReaction }, ref) => {
  const navigate = useNavigate();
  const { user, openLoginModal } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirm = (id) => {
    if (isSubmitting) {
      return;
    }

    return confirm({
      title: "Confirm delete comment",
      content: "Are you sure you want to delete this comment?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onDeteleComment(id);
      },
    });
  };

  const onDeteleComment = async (id) => {
    try {
      setIsSubmitting(true);
      const response = await deleteComment(id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Deleted comment successfully!");
        onRemoveComment(id);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCommentReaction = (action, commentId, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (isSubmitting) {
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(commentId, reactionType);
    } else {
      doRemoveCommentReaction(commentId, reactionType);
    }
  };

  const doAddCommentReaction = async (commentId, reactionType) => {
    const payload = {
      comment_id: commentId,
      reaction_type: reactionType,
    };

    try {
      setIsSubmitting(true);
      const { error } = await addCommentReaction(payload);
      if (error) {
        message.error(error.message);
      } else {
        onChangeCommentReaction(commentId, reactionType, ADD_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const doRemoveCommentReaction = async (commentId, reactionType) => {
    try {
      setIsSubmitting(true);
      const { error } = await removeCommentReaction(reactionType, commentId);
      if (error) {
        message.error(error);
      } else {
        onChangeCommentReaction(commentId, reactionType, REMOVE_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <List.Item
      ref={ref}
      actions={[
        <Button
          style={{ border: "none" }}
          onClick={() =>
            item.active_for_user_heart
              ? handleCommentReaction("REMOVE_REACTION", item.id, HEART_REACTION)
              : handleCommentReaction("ADD_REACTION", item.id, HEART_REACTION)
          }
          icon={item.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}>
          {item.hearts_count}
        </Button>,
        <Button
          style={{ border: "none" }}
          onClick={() =>
            item.active_for_user_like
              ? handleCommentReaction("REMOVE_REACTION", item.id, LIKE_REACTION)
              : handleCommentReaction("ADD_REACTION", item.id, LIKE_REACTION)
          }
          icon={item.active_for_user_like ? <LikeFilled style={{ color: "#1677ff" }} /> : <LikeOutlined />}>
          {item.likes_count}
        </Button>,
      ]}>
      <List.Item.Meta
        avatar={
          <Avatar
            style={{ cursor: "pointer" }}
            size="large"
            src={item.user_avatar}
            onClick={() => navigate(`/profile/${item.user_id}`)}
          />
        }
        title={
          <Flex justify="space-between" align="center">
            <Flex vertical="vertical">
              <span style={{ fontWeight: "700" }}>{item.user_name}</span>
              <span style={{ fontWeight: "normal", fontSize: "0.75rem", color: "rgba(113,128,150), 0.75)" }}>
                {item.created_at}
              </span>
            </Flex>
            {user && user.user_id === item.user_id && (
              <Button onClick={() => showDeleteConfirm(item.id)} shape="circle" icon={<DeleteOutlined />} />
            )}
          </Flex>
        }
      />
      <Paragraph ellipsis={{ rows: 5, expandable: true }}>{item.comment}</Paragraph>
    </List.Item>
  );
});

export default Comment;
