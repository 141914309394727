import { Divider, Flex } from "antd";
import CustomeSvgIcon from "../../../../components/CustomSvgIcon";
import { clockIcon } from "../../../../utils/svgIcons";
import "./Notes.css";

const styleIcon = { margin: "6px", fill: "rgb(202, 202, 202)" };

const Notes = ({ notes }) => {
  return (
    <>
      <Divider style={{ fontWeight: "bold", marginTop: "3rem" }}>Notes</Divider>
      <Flex align="center" justify="space-around">
        <div className="container">
          <CustomeSvgIcon style={styleIcon} height={30} icon={clockIcon} />
          <div className="arrow-line"></div>
        </div>
        <Flex gap="large" wrap justify="center" className="horizontal-arrow-container">
          {notes.map((item) => {
            return (
              <Flex key={item.id} vertical align="center">
                <img style={{ marginBottom: "1rem" }} height={50} width={50} alt={item.name} src={item.image_url} />
                <span>{item.name}</span>
              </Flex>
            );
          })}
        </Flex>
        <div className="container-end">
          <div className="arrow-line"></div>
          <div className="end-block"></div>
        </div>
      </Flex>
    </>
  );
};

export default Notes;
