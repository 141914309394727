import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Divider, Flex, Progress, message } from "antd";
import { useAuth } from "../../../../contexts/AuthContext";
import { sonIcon, moonIcon } from "../../../../utils/svgIcons";
import CustomeSvgIcon from "../../../../components/CustomSvgIcon";
import { getMaxValueFromObject } from "../../../../utils/service";
import { addVotes } from "../../../../services/supabaseClient";
import useWindowSize from "../../../../hooks/useWindowSize";

const styleIcon = { marginBottom: "12px", fill: "rgb(202, 202, 202)" };
const styleIconActive = { marginBottom: "12px", fill: "#1677ff" };

const progressStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "12px",
};
const porgressPercentPosition = {
  type: "none",
};

const WeatherVote = ({ data, userVote }) => {
  const { isMobileView } = useWindowSize();

  const { user } = useAuth();
  const { productId } = useParams();

  const [day, setDay] = useState(null);
  const [night, setNight] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const [valueVote, setValueVote] = useState(null);
  const [dataUserVote, setDataUserVote] = useState(null);

  const setInitValues = (dataInit, initUserVote) => {
    setDataVote(dataInit);
    const max = getMaxValueFromObject(dataInit);

    const initDay = dataInit?.day ? (dataInit.day / max) * 100 : 0;
    setDay(initDay);

    const initNight = dataInit?.night ? (dataInit.night / max) * 100 : 0;
    setNight(initNight);

    if (initUserVote && initUserVote.weathers) {
      setValueVote(initUserVote.weathers);
      setDataUserVote(initUserVote);
    } else {
      setValueVote(null);
      setDataUserVote({ weathers: null });
    }
  };

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote]);

  const updateDataVote = (value, newStatus, newDataUserVotes) => {
    const newValueVote = {
      ...valueVote,
      [value]: data[newStatus] + 1,
    };
    setValueVote(newValueVote);

    const newDataVotes = {
      ...dataVote,
      [value]: newStatus ? dataVote[value] + 1 : dataVote[value] - 1,
    };

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onVote = async (value) => {
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        let newStatus = true;
        if (dataUserVote?.weathers?.hasOwnProperty(value)) {
          newStatus = !dataUserVote?.weathers[value];
        }

        const votes = {
          weathers: {
            ...dataUserVote.weathers,
            [value]: newStatus,
          },
        };

        const { error } = await addVotes(productId, votes);

        if (error) {
          message.error(error.message);
        } else {
          message.success("Your vote successfully saved!");
          setValueVote(value);
          updateDataVote(value, newStatus, votes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const progressSize = [70, !isMobileView ? 10 : 8];
  const iconHeight = !isMobileView ? 30 : 20;

  return (
    <>
      <Divider style={{ marginBottom: "2rem", marginTop: isMobileView ? "24px" : "" }}>
        <span style={{ fontSize: "14px" }}>Weathers</span>
      </Divider>

      <Flex justify="space-around">
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("day")}>
            <CustomeSvgIcon
              style={valueVote?.day ? styleIconActive : styleIcon}
              width="32"
              height={iconHeight}
              icon={sonIcon}
            />
            <span>day</span>
          </Flex>

          <Progress style={progressStyle} percent={day} percentPosition={porgressPercentPosition} size={progressSize} />
        </Flex>
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("night")}>
            <CustomeSvgIcon
              style={valueVote?.night ? styleIconActive : styleIcon}
              height={iconHeight}
              icon={moonIcon}
            />
            <span>night</span>
          </Flex>

          <Progress
            style={progressStyle}
            percent={night}
            percentPosition={porgressPercentPosition}
            size={progressSize}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default WeatherVote;
