import { Flex, Rate, Badge } from "antd";

const badgeStyle = {
  backgroundColor: "rgb(229 229 229 / 37%)",
  color: "rgba(0, 0, 0, 0.88)",
  fontWeight: "600",
  marginLeft: "6px",
};

const Rating = ({ value, badge, disabled = true, onChange, ...props }) => {
  return (
    <Flex align="center" {...props}>
      <Rate onChange={onChange} disabled={disabled} defaultValue={value} style={{ fontSize: "16px" }} />
      <Badge count={badge ?? value} size="small" style={badgeStyle} />
    </Flex>
  );
};

export default Rating;
