import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { message } from "antd";
import { supabase, getUser } from "../services/supabaseClient";
import { setItemWithExpiry, getItemWithExpiry, EXPIRES_IN } from "../utils/localstorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(getItemWithExpiry("user"));
  const [showLoginModal, setShowLoginModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const getVfraUser = (userId) => {
    return new Promise(async (resolve) => {
      try {
        const { error, data } = await getUser(userId);
        if (error) {
          message.error(error);
          resolve(null);
        } else {
          resolve(data.length > 0 ? data[0] : null);
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const refreshVfraUser = async () => {
    const data = await getVfraUser(user.user_id);
    setUser((prevUser) => {
      return {
        ...prevUser,
        vfra_user: data ?? null,
      };
    });
  };

  const initUser = useCallback(async (session) => {
    const data = await getVfraUser(session?.user.id);
    const userTemp = { ...session?.user.user_metadata, user_id: session?.user.id, vfra_user: data ?? null };
    setUser(userTemp);
    setItemWithExpiry("user", userTemp, EXPIRES_IN);
    setSession(session);
  }, []);

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      setSession(session);

      if (session && !user) {
        initUser(session);
      }
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "SIGNED_IN") {
        if (session && !user) {
          initUser(session);
          message.success("Sign in successful!");
        }
      } else if (event === "SIGNED_OUT") {
        setUser(null);
        localStorage.removeItem("user");
        message.success("Signed out successful!");
      }
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [user, initUser]);

  // Automatically refresh session
  useEffect(() => {
    if (session) {
      const refreshToken = async () => {
        const { error } = await supabase.auth.refreshSession();
        if (error) {
          console.log("Error refreshing session:", error.message);
        }
      };

      // Time until the session expires
      const expiresIn = session.expires_in * 1000;

      // Refresh the session 1 minute before it expires
      const refreshTimeout = setTimeout(() => {
        refreshToken();
      }, expiresIn - 60000);

      return () => clearTimeout(refreshTimeout);
    }
  }, [session]);

  return (
    <AuthContext.Provider value={{ user, showLoginModal, openLoginModal, closeLoginModal, refreshVfraUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
