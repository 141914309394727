import React from "react";
import { Button } from "antd";
import { GoogleCircleFilled } from "@ant-design/icons";
import { supabase } from "../services/supabaseClient";

const GoogleLoginButton = () => {
  const signInWithGoogle = async () => {
    const currentUrl = window.location.href.split("#")[0];
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: currentUrl,
      },
    });
  };

  return (
    <Button onClick={() => signInWithGoogle()} block icon={<GoogleCircleFilled />}>
      Sign up with Google
    </Button>
  );
};

export default GoogleLoginButton;
