export const EXPIRES_IN = process.env.NODE_ENV === "development" ? 604795 : 300;

export const setItemWithExpiry = (key, value, seconds) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + seconds * 1000,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
