import { useState, useEffect } from "react";
import { Input, Spin } from "antd";

import _ from "lodash";

const DebouncedInput = ({ onDebouncedChange, loading, ...props }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const debouncedChangeHandler = _.debounce(onDebouncedChange, 300);
    debouncedChangeHandler(inputValue);

    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [inputValue, onDebouncedChange]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Input
      {...props}
      allowClear
      value={inputValue}
      onChange={handleChange}
      size="large"
      placeholder="Search designers by name, description..."
      suffix={loading && <Spin />}
    />
  );
};

export default DebouncedInput;
