import "../layouts.css";
import { Outlet } from "react-router-dom";
import { Layout, theme } from "antd";

import HeaderLayout from "../HeaderLayout/HeaderLayout";

const { Content, Footer } = Layout;

const MainLayout = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <HeaderLayout />
      <Content>
        <div
          className="vfra-content"
          style={{
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}>
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>Vfra ©{new Date().getFullYear()} All right reserved</Footer>
    </Layout>
  );
};

export default MainLayout;
