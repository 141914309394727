import React from "react";
import "./ThreePartsProgress.css";

const ThreePartsProgress = ({ part1, part2, part3, height = 20 }) => {
  return (
    <div className="three-part-progress-bar-container" style={{ height: `${height}px` }}>
      <div className="three-part-progress-bar">
        {part1 + part2 + part3 === 0 && (
          <div className="text-center">
            <p style={{ color: "rgb(100 100 100 / 70%)", fontSize: "0.8rem" }}>No data!</p>
          </div>
        )}
        <div
          className="three-part-progress-bar-fill part1"
          style={{
            width: `${part1}%`,
          }}>
          {part1 > 0 && `${part1.toFixed(0)}% female`}
        </div>
        <div
          className="three-part-progress-bar-fill part2"
          style={{
            width: `${part2}%`,
          }}>
          {part2 > 0 && `${part2.toFixed(0)}% unisex`}
        </div>
        <div
          className="three-part-progress-bar-fill part3"
          style={{
            width: `${part3}%`,
          }}>
          {part3 > 0 && `${part3.toFixed(0)}% male`}
        </div>
      </div>
    </div>
  );
};

export default ThreePartsProgress;
