import { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Avatar, FloatButton, Space, Button, Typography, Drawer, Tag, Tooltip, message, Carousel } from "antd";
import {
  HeartOutlined,
  CommentOutlined,
  CloseOutlined,
  SaveOutlined,
  SaveFilled,
  HeartFilled,
} from "@ant-design/icons";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAuth } from "../../../contexts/AuthContext";
import { formatISOToUTC7 } from "../../../utils/service";
import { follow, addPostReaction, removePostReaction } from "../../../services/supabaseClient";
import Comments from "./Comments/Comments";

import "./Feed.css";
const { Paragraph } = Typography;
const reactionActions = {
  add: "add",
  remove: "remove",
};
const reactionTypes = {
  save: "save",
  heart: "heart",
};

const Feed = forwardRef(({ post, index, onChangeReaction }, ref) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const { user, openLoginModal } = useAuth();

  // handle comments
  const [showComments, setShowComments] = useState(false);
  const [commentsAnimationState, setCommentsAnimationState] = useState("");
  const [feedAnimationState, setFeedAnimationState] = useState("");

  // handle relate perfumes
  const [showPerfumes, setShowPerfumes] = useState(false);
  const [perfumesAnimationState, setPerfumesAnimationState] = useState("");

  // handle perfume accords
  const [perfume, setPerfume] = useState(post?.perfumes[0] ?? null);
  const [showAccords, setShowAccords] = useState(post?.perfumes[0] ? true : false);
  const [accordsAnimationState, setAccordsAnimationState] = useState("");

  // handle follow
  const [isFollowing, setIsFollowing] = useState(false);

  const converted_created_at = formatISOToUTC7(post.created_at);

  const onShowComments = () => {
    if (!isMobileView) {
      setCommentsAnimationState("");
      setFeedAnimationState("appear-comments");
    }

    setShowComments(true);
  };

  const onHideComments = () => {
    if (!isMobileView) {
      setCommentsAnimationState("hide");
      setFeedAnimationState("hide-comments");
      setTimeout(() => setShowComments(false), 200);
    } else {
      setShowComments(false);
    }
  };

  const onTriggerRelatePerfumes = () => {
    if (!showPerfumes) {
      setPerfumesAnimationState("");
      setShowPerfumes(true);
    } else {
      setPerfumesAnimationState("hide-perfumes");
      setTimeout(() => {
        setShowPerfumes(false);
        setPerfume(null);
      }, 200);

      if (perfume) {
        setAccordsAnimationState("hide-accords");
        setTimeout(() => {
          setPerfume(null);
        }, 200);
      }
    }
  };

  const onTriggerAccords = (perfume) => {
    if (!showAccords) {
      setPerfume(perfume);
      setAccordsAnimationState("");
      setShowAccords(true);
    } else {
      setAccordsAnimationState("hide-accords");
      setTimeout(() => {
        setShowAccords(false);
        setPerfume(null);
      }, 200);
    }
  };

  const onFollow = async () => {
    if (!user) {
      openLoginModal();
      return;
    }

    try {
      setIsFollowing(true);

      const payload = {
        followee_id: post.owner.id,
      };

      const { error } = await follow(payload);

      if (error) {
        message.error(error.message);
      } else {
        message.success("Follow successfuly!");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsFollowing(false);
    }
  };

  // mobile view
  const WIDTH_CARD = isMobileView ? "100%" : "30%";
  const styleGroupFloatButton = {
    position: "absolute",
    right: isMobileView ? "12px" : showComments ? "12px" : "-54px",
    bottom: isMobileView ? "25%" : "20%",
  };

  // card post
  const cardPostStyle = {
    width: WIDTH_CARD,
    borderRadius: showComments ? "12px 0 0 12px" : "12px",
    borderRight: showComments ? 0 : "none",
  };
  const cardPostClass = `${showComments ? "left-box-shadow" : "normal-box-shadow"}`;

  // card comments
  const cardCommentsStyle = {
    width: WIDTH_CARD,
    borderRadius: showComments ? "0 12px 12px 0" : "12px",
  };
  const cardCommentsClass = `${showComments ? commentsAnimationState || "show right-box-shadow" : "hidden"}`;

  // float button
  const stringCommentCountLength = post.total_comments;
  const offsetCommentsBadge =
    stringCommentCountLength === 4
      ? [3, 54]
      : stringCommentCountLength === 3
      ? [0, 54]
      : stringCommentCountLength === 2
      ? [-4, 54]
      : [-13, 54];

  // relate perfumes
  const imageRelatePerfumeHeight = "32px";
  const styleRelatePerfumeContainer = {
    right: isMobileView ? "64px" : showComments ? "72px" : "14px",
    bottom: isMobileView ? "25%" : "20%",
  };

  // handle reactions
  const [isReacting, setIsReacting] = useState(false);

  const handleReactions = (action, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (isReacting) {
      return;
    }

    if (action === reactionActions.add) {
      doAddReaction(reactionType);
    } else {
      doRemoveReaction(reactionType);
    }
  };

  const doAddReaction = async (reactionType) => {
    try {
      setIsReacting(true);
      addPostReaction(post.id, reactionType);
      onChangeReaction(post.id, index, reactionActions.add, reactionType);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsReacting(false);
    }
  };

  const doRemoveReaction = async (reactionType) => {
    try {
      setIsReacting(true);
      removePostReaction(post.id, reactionType);
      onChangeReaction(post.id, index, reactionActions.remove, reactionType);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsReacting(false);
    }
  };

  const maxCountAccords = isMobileView ? 4 : 5;

  return (
    <Flex ref={ref} justify="center" className="vfra-feed-container" data-index={index}>
      <Flex
        vertical
        justify="space-between"
        className={`vfra-post-container ${feedAnimationState} ${cardPostClass}`}
        style={cardPostStyle}>
        {post.media_data && post.media_data.urls && post.media_data.urls.length > 1 && (
          <Carousel slidesToShow={1} slidesToScroll={1} dotPosition="bottom" arrows infinite={false}>
            {post.media_data.urls.map((item) => (
              <Flex key={item} justify="center" align="center" style={{ height: "80%" }}>
                <img alt="" style={{ maxWidth: "100%", width: isMobileView ? "80%" : "" }} src={item} />
              </Flex>
            ))}
          </Carousel>
        )}

        {post.media_data && post.media_data.urls && post.media_data.urls.length === 1 && (
          <Flex justify="center" align="center" style={{ height: "80%" }}>
            <img alt="" style={{ maxWidth: "100%", width: isMobileView ? "80%" : "" }} src={post.media_data.urls[0]} />
          </Flex>
        )}

        <div>
          <Flex align="center" gap={12}>
            <Avatar
              style={{ cursor: "pointer" }}
              size="large"
              src={post.owner.avatar}
              onClick={() => navigate(`/profile/${post.owner.id}`)}
            />

            <Flex vertical="vertical" gap={3}>
              <span className="one-line-ellipsis" style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                {post.owner.name}
              </span>

              <span style={{ fontWeight: "normal", fontSize: "0.7rem", color: "#606060" }}>{converted_created_at}</span>
            </Flex>

            {user && post.owner.followed && (
              <Tag color="processing" bordered={false} style={{ fontSize: "0.7rem" }}>
                Following
              </Tag>
            )}

            {!post.owner.followed && (
              <Button
                size="small"
                loading={isFollowing}
                shape="round"
                style={{ fontSize: "0.7rem", fontWeight: "600" }}
                onClick={onFollow}>
                Follow
              </Button>
            )}
          </Flex>

          <Paragraph
            style={{ marginBottom: 0, marginTop: "12px", fontSize: "0.9rem" }}
            ellipsis={{ rows: 2, expandable: true }}>
            {post.caption}
          </Paragraph>
        </div>

        <FloatButton.Group shape="circle" style={styleGroupFloatButton}>
          <Space size={30} direction="vertical">
            <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Save this post</span>}>
              <FloatButton
                badge={{ count: post.total_saves, overflowCount: 999, offset: offsetCommentsBadge, size: "small" }}
                onClick={() =>
                  handleReactions(
                    post.active_for_user_save ? reactionActions.remove : reactionActions.add,
                    reactionTypes.save
                  )
                }
                icon={post.active_for_user_save ? <SaveFilled style={{ color: "#1677ff" }} /> : <SaveOutlined />}
              />
            </Tooltip>

            <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">I love this post</span>}>
              <FloatButton
                badge={{ count: post.total_hearts, overflowCount: 999, offset: offsetCommentsBadge, size: "small" }}
                onClick={() =>
                  handleReactions(
                    post.active_for_user_heart ? reactionActions.remove : reactionActions.add,
                    reactionTypes.heart
                  )
                }
                icon={post.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}
              />
            </Tooltip>

            <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Comments</span>}>
              <FloatButton
                badge={{ count: post.total_comments, overflowCount: 999, offset: offsetCommentsBadge, size: "small" }}
                onClick={onShowComments}
                icon={<CommentOutlined />}
              />
            </Tooltip>

            {post.perfumes && (
              <Tooltip
                placement="right"
                title={!isMobileView && <span className="vfra-tooltip"> Related perfumers</span>}>
                <FloatButton
                  badge={{
                    count: post.perfumes.length,
                    overflowCount: 999,
                    offset: offsetCommentsBadge,
                    size: "small",
                  }}
                  onClick={onTriggerRelatePerfumes}
                  icon={showPerfumes ? <CloseOutlined /> : <img alt="" height={25} src={post.perfumes[0].image_url} />}
                />
              </Tooltip>
            )}
          </Space>
        </FloatButton.Group>

        {post.perfumes && (
          <Flex
            justify="end"
            style={styleRelatePerfumeContainer}
            className={`vfra-relate-perfumes-container ${perfumesAnimationState} ${
              showPerfumes ? "show-perfumes" : "hide-perfumes hidden"
            }`}
            gap={30}
            vertical>
            {post.perfumes.map((p) => (
              <Flex
                key={p.id}
                align="center"
                className={`vfra-relate-perfume ${perfume && p.id === perfume.id ? "vfra-accords-active" : ""}`}
                onClick={() => onTriggerAccords(p)}>
                <img alt="" style={{ maxHeight: "100%" }} height={imageRelatePerfumeHeight} src={p.image_url} />
              </Flex>
            ))}
          </Flex>
        )}

        {perfume && (
          <Flex
            vertical
            gap={12}
            className={`vfra-accords-container ${accordsAnimationState} ${
              showAccords ? "show-accords" : "hide-accords hidden"
            }`}>
            <Flex justify="center">
              <span className="vfra-title-accords-popover" onClick={() => navigate(`/product/${perfume.id}`)}>
                {perfume.name}
              </span>
            </Flex>

            <Flex
              gap={12}
              justify="center"
              className={perfume.accords.length > maxCountAccords ? "vfra-accords-list" : ""}>
              {perfume.accords.map((accord) => (
                <Tag
                  key={accord.name}
                  title={accord.name}
                  className="one-line-ellipsis"
                  style={{ fontSize: "0.6rem" }}
                  color={accord.color_code}>
                  {accord.name}
                </Tag>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>

      {!isMobileView && (
        <div className={`vfra-comments-container ${cardCommentsClass}`} style={cardCommentsStyle}>
          <Flex justify="space-between" align="center">
            <span style={{ fontWeight: "bold", fontSize: "1rem", marginBottom: 0, marginRight: "6px" }}>
              Comments
              {post.comment_count > 0 && (
                <span style={{ fontWeight: "normal", color: "rgb(96, 96, 96)" }}>{post.comment_count}</span>
              )}
            </span>
            <Button
              style={{ marginRight: "-4px" }}
              onClick={onHideComments}
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </Flex>

          {showComments && <Comments postId={post.id} />}
        </div>
      )}

      {isMobileView && (
        <Drawer
          title={
            <Flex justify="space-between" align="center">
              <span>Comments</span>{" "}
              <Button onClick={onHideComments} type="text" shape="circle" icon={<CloseOutlined />} />
            </Flex>
          }
          height="70%"
          placement="bottom"
          closable={false}
          onClose={onHideComments}
          open={showComments}
          getContainer={false}>
          <Comments postId={post.id} />
        </Drawer>
      )}
    </Flex>
  );
});

export default Feed;
