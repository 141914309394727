import { useState, useCallback, useEffect, memo } from "react";
import { Flex, Divider, Row, Col, Button, message } from "antd";
import { formatNumber } from "../../../utils/service";
import { search_brands } from "../../../services/meilisearchBrands";
import BrandItem from "../BrandItem/BrandItem";
import useWindowSize from "../../../hooks/useWindowSize";

const SearchBrands = memo(({ searchValue, onLoadingChange }) => {
  const { isMobileView } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const onSearch = useCallback(async () => {
    try {
      setLoading(true);
      const searchResults = await search_brands(searchValue, page);

      setTotal(searchResults.estimatedTotalHits);
      if (page > 1) {
        setBrands((prevBrands) => [...prevBrands, ...searchResults.hits]);
      } else {
        setBrands(searchResults.hits);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [searchValue, page]);

  useEffect(() => {
    if (searchValue) {
      onSearch();
    }
  }, [searchValue, onSearch]);

  useEffect(() => {
    onLoadingChange(loading);
  }, [loading, onLoadingChange]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Divider style={{ marginBottom: "1.5rem" }}>Designers search results ({formatNumber(total)})</Divider>
      <Row gutter={!isMobileView ? [12, 12] : [24, 24]} justify="center" vertical="vertical">
        {brands.map((item) => {
          return (
            <Col key={item.id} span={!isMobileView ? 6 : 24}>
              <BrandItem designer={item} />
            </Col>
          );
        })}
      </Row>

      {brands.length < total && (
        <Flex justify="center" style={{ marginTop: "24px" }}>
          <Button onClick={handleLoadMore} loading={loading} iconPosition="end">
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
});

export default SearchBrands;
