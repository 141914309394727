import { useState, useEffect, useRef, useCallback } from "react";
import { message, Skeleton, Flex, Button } from "antd";
import { getCommentsByPost } from "../../../../services/supabaseClient";
import {
  formatISOToUTC7,
  countReactions,
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  REMOVE_REACTION_ACTION,
} from "../../../../utils/service";
import CommentItem from "./CommentItem/CommentItem";
import CommentArea from "./CommentArea/CommentArea";

const PAGE_SIZE = 10;

const Comments = ({ postId }) => {
  const [loading, setLoading] = useState(true);
  const [loadingmore, setLoadingmore] = useState(false);
  const [dataComments, setDataComments] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const listRef = useRef(null);

  const fetchDataComments = useCallback(async () => {
    try {
      if (page > 1) setLoadingmore(true);
      const { data, error, count } = await getCommentsByPost(postId, page, PAGE_SIZE);
      if (error) {
        message.error(error.message);
      } else {
        setCount(count);
        const convertedData = data.map((e) => {
          const { reaction_count: likes_count, active_for_user: active_for_user_like } = countReactions(
            e.reactions_metadata,
            "like"
          );
          const { reaction_count: hearts_count, active_for_user: active_for_user_heart } = countReactions(
            e.reactions_metadata,
            "heart"
          );
          const created_at = formatISOToUTC7(e.created_at);
          return {
            id: e.id,
            user_id: e.user_id,
            comment: e.comment,
            likes_count,
            active_for_user_like,
            hearts_count,
            active_for_user_heart,
            replies_count: e.replies_count,
            user_name: e.user.name,
            user_avatar: e.user.avatar,
            created_at,
          };
        });

        setDataComments((prev) => {
          if (page > 1) {
            return [...prev, ...convertedData];
          }

          return convertedData;
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
      setLoadingmore(false);
      if (page === 1) {
        scrollToTop();
      }
    }
  }, [page, postId]);

  useEffect(() => {
    fetchDataComments();
  }, [page, postId, fetchDataComments]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleAddComment = () => {
    setPage(1);
    fetchDataComments();
  };

  const handleRemoveComment = (id) => {
    const dataAfterRemove = dataComments.filter((c) => c.id !== id);
    setDataComments(dataAfterRemove);
  };

  const handleChangeCommentReaction = (commentId, reactionType, action) => {
    for (let i = 0; i < dataComments.length; i++) {
      if (dataComments[i].id === commentId) {
        if (reactionType === HEART_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].hearts_count++;
            dataComments[i].active_for_user_heart = true;
          } else if (action === REMOVE_REACTION_ACTION) {
            dataComments[i].hearts_count--;
            dataComments[i].active_for_user_heart = false;
          }
        } else if (reactionType === LIKE_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].likes_count++;
            dataComments[i].active_for_user_like = true;
          } else if (action === REMOVE_REACTION_ACTION) {
            dataComments[i].likes_count--;
            dataComments[i].active_for_user_like = false;
          }
        }
      }
    }

    setDataComments([...dataComments]);
  };

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({ scrollTop: 0 });
    }
  };

  return (
    <>
      {loading && (
        <div style={{ padding: "24px" }}>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
        </div>
      )}

      <Flex vertical style={{ height: "100%" }}>
        <div style={{ paddingTop: "12px", flexGrow: 1, overflow: "hidden" }}>
          {!loading && dataComments.length === 0 && (
            <Flex align="center" justify="center" style={{ height: "100%" }}>
              <p style={{ color: "rgb(100 100 100)", fontSize: "0.75rem" }}>Write first comment for this post!</p>
            </Flex>
          )}

          {!loading && dataComments.length > 0 && (
            <Flex vertical size="large" className="vfra-scroll">
              {dataComments.map((item) => (
                <CommentItem
                  key={item.id}
                  item={item}
                  onRemoveComment={handleRemoveComment}
                  onChangeCommentReaction={handleChangeCommentReaction}
                />
              ))}

              {count > dataComments.length && (
                <Flex justify="center">
                  <Button
                    style={{ fontSize: "0.7rem" }}
                    size="small"
                    onClick={handleLoadMore}
                    loading={loadingmore}
                    iconPosition="end">
                    Load more
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </div>

        <CommentArea postId={postId} onAddComment={handleAddComment} />
      </Flex>
    </>
  );
};

export default Comments;
