import { createClient } from "@supabase/supabase-js";
const supabaseUrl = "https://spfycswrpbrwxqetirar.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNwZnljc3dycGJyd3hxZXRpcmFyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg1MzgwMzgsImV4cCI6MjAzNDExNDAzOH0._3A2ujEUu1yM5k8jlWONDg7PaEl1X2H7p6f0ZX0LRzk";

// const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://api.perfumerates.com";
const endpoint = "https://api.perfumerates.com";

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  autoRefreshToken: true,
});

const fetchBase = async (method, url, body, accessToken = null) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken ?? supabaseAnonKey}`,
    },
    body: body ? JSON.stringify(body) : null,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  if (response.status === 204) {
    return {
      status: response.status,
    };
  }

  try {
    const results = await response.json();
    return results;
  } catch (error) {
    return { status: 204 };
  }
};

//======================== PERFUMES =============================

// GET perfumes/:id
export const getPerfumeById = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/${pid}`);
};

//======================== PERFUMES - VOTES =============================
// GET perfumes/:id/votes
export const getPerfumeRates = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/${pid}/votes`);
};

// GET perfumes/:id/votes/me
export const getOwnVotes = async (pid) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("GET", `${endpoint}/perfumes/${pid}/votes/me`, null, accessToken);
};

// POST perfumes/:id/votes/me
export const addVotes = async (pid, objectDataVote) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/perfumes/${pid}/votes/me`, objectDataVote, accessToken);
};

//======================== DESIGNERS ============================

// GET brands/lite
export const getAllBrandsLite = async () => {
  return fetchBase("GET", `${endpoint}/brands/lite`);
};

// GET brands/:id
export const getBrandById = async (brandId) => {
  return fetchBase("GET", `${endpoint}/brands/${brandId}`);
};

//======================== COMMENTS =============================

// GET comments/users/:uid?page=:page&pageSize=:pageSize
export const getCommentsByUser = (uid, page, pageSize) => {
  return fetchBase("GET", `${endpoint}/users/comments/${uid}?page=${page}&pageSize=${pageSize}`);
};

// GET comments/perfumes/:topic?page=:page&pageSize=:pageSize
export const getCommentsByTopic = (topic, page, pageSize) => {
  return fetchBase("GET", `${endpoint}/perfumes/comments/${topic}?page=${page}&pageSize=${pageSize}`);
};

// POST perfumes/comments/:id
export const addComment = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/perfumes/comments/${payload["topic"]}`, payload, accessToken);
};

// DELETE comments/:id
export const deleteComment = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("DELETE", `${endpoint}/perfumes/comments/${id}`, null, accessToken);
};

// POST comments/:id/reactions
export const addCommentReaction = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "POST",
    `${endpoint}/perfumes/comments/${payload["comment_id"]}/reactions`,
    {
      reaction_type: payload["reaction_type"],
    },
    accessToken
  );
};

// DELETE comments/:id/reactions
export const removeCommentReaction = async (reaction_type, comment_id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "DELETE",
    `${endpoint}/perfumes/comments/${comment_id}/reactions`,
    {
      reaction_type: reaction_type,
    },
    accessToken
  );
};

//======================== PROFILE =============================
// GET /users/perfumes?uid=:uid
export const getPerfumes = async (uid) => {
  return fetchBase("GET", `${endpoint}/users/perfumes/?uid=${uid}`);
};

// GET /users/perfumes/requests
export const getRequestPerfumes = async () => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("GET", `${endpoint}/users/perfumes/requests`, null, accessToken);
};

// POST /users/perfumes/requests
export const addRequestPerfume = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/users/perfumes/requests`, payload, accessToken);
};

// DELETE /users/perfumes/requests/:id
export const removeRequestPerfume = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("DELETE", `${endpoint}/users/perfumes/requests/${id}`, null, accessToken);
};

// PATCH /users/perfumes/requests/:id
export const updateRequestPerfume = async (id, payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("PATCH", `${endpoint}/users/perfumes/requests/${id}`, payload, accessToken);
};

// GET /users
export const getUser = async (uid) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : null;

  return fetchBase("GET", `${endpoint}/users/?uid=${uid}`, null, accessToken);
};

// GET /users/avatar-upload-key
export const getAvatarUploadKey = async () => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("GET", `${endpoint}/users/avatar-upload-key`, null, accessToken);
};

export const uploadToSignedURL = async (field, path, token, file) => {
  return supabase.storage.from(field).uploadToSignedUrl(path, token, file, {
    cacheControl: "31556926",
    upsert: true,
  });
};

export const getPublicUrl = async (field, fullPath) => {
  return supabase.storage.from(field).getPublicUrl(fullPath);
};

// POST /users
export const updateUserInfo = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/users`, payload, accessToken);
};

// GET /users/perfumes/requests/perfume-img-upload-key
export const getImagePerfumeUploadKey = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("GET", `${endpoint}/users/perfumes/requests/perfume-img-upload-key/${id}`, null, accessToken);
};

// GET users/perfumes/requests/brand-img-upload-key
export const getLogoBrandUploadKey = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("GET", `${endpoint}/users/perfumes/requests/brand-img-upload-key/${id}`, null, accessToken);
};

//======================== EXPLORER =============================
// GET explorer/feeds
export const getFeeds = async (pageSize) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : null;

  if (accessToken) {
    return fetchBase("GET", `${endpoint}/explorer/feeds/?pageSize=${pageSize}`, null, accessToken);
  } else {
    return fetchBase("GET", `${endpoint}/explorer/feeds/?pageSize=${pageSize}`, null);
  }
};

// POST explorer/feeds/view
export const markViewedFeeds = (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/feeds/view`, payload);
};

// GET /explorer/post/details
export const getPostDetails = async (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/post/details`, payload);
};

// GET /explorer/comments
export const getCommentsByPost = (topic, page, pageSize) => {
  return fetchBase("GET", `${endpoint}/explorer/comments/${topic}?page=${page}&pageSize=${pageSize}`);
};

// POST /explorer/comments/:id
export const addExplorerComment = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/explorer/comments/${payload["topic"]}`, payload, accessToken);
};

// DELETE /explorer/comments/:id
export const deleteExplorerComment = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("DELETE", `${endpoint}/explorer/comments/${id}`, null, accessToken);
};

// POST explorer/comments/:id/reactions
export const addExplorerCommentReaction = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "POST",
    `${endpoint}/explorer/comments/${payload["comment_id"]}/reactions`,
    {
      reaction_type: payload["reaction_type"],
    },
    accessToken
  );
};

// DELETE explorer/comments/:id/reactions
export const removeExplorerCommentReaction = async (reaction_type, comment_id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "DELETE",
    `${endpoint}/explorer/comments/${comment_id}/reactions`,
    {
      reaction_type: reaction_type,
    },
    accessToken
  );
};

//======================== FOLLOW =============================

// POST /users/follow
export const follow = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/users/follow`, payload, accessToken);
};

// DELETE /users/follow
export const unfollow = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("DELETE", `${endpoint}/users/follow`, payload, accessToken);
};

//======================== POST =============================

// GET /explorer/post
export const getPosts = (topic, page, pageSize) => {
  return fetchBase("GET", `${endpoint}/explorer/post/${topic}?page=${page}&pageSize=${pageSize}`);
};

// POST /explorer/post
export const createPost = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/explorer/post`, payload, accessToken);
};

// DELETE /explorer/post
export const removePost = async (id) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("DELETE", `${endpoint}/explorer/post/${id}`, null, accessToken);
};

// PATCH /explorer/post/:pid
export const updatePost = async (id, payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("PATCH", `${endpoint}/explorer/post/${id}`, payload, accessToken);
};

// GET /explorer/post/post-images-upload-key/:pid?n
export const getPostImagesUploadKey = async (pid, total_image) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "GET",
    `${endpoint}/explorer/post/post-images-upload-key/${pid}?n=${total_image}`,
    null,
    accessToken
  );
};

// POST /explorer/post/views
export const markViewedPost = async (payload) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase("POST", `${endpoint}/explorer/post/views`, payload, accessToken);
};

// POST /explorer/post/:pid/reactions
export const addPostReaction = async (post_id, reaction_type) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "POST",
    `${endpoint}/explorer/post/${post_id}/reactions`,
    {
      reaction_type,
    },
    accessToken
  );
};

// DELETE explorer/post/:id/reactions
export const removePostReaction = async (post_id, reaction_type) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  return fetchBase(
    "DELETE",
    `${endpoint}/explorer/post/${post_id}/reactions`,
    {
      reaction_type,
    },
    accessToken
  );
};
