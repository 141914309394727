import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Flex, Button, message, Typography, Modal, Space } from "antd";
import { LikeOutlined, HeartOutlined, DeleteOutlined, LikeFilled, HeartFilled } from "@ant-design/icons";
import {
  deleteExplorerComment,
  addExplorerCommentReaction,
  removeExplorerCommentReaction,
} from "../../../../../services/supabaseClient";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  REMOVE_REACTION_ACTION,
} from "../../../../../utils/service";

const { Paragraph } = Typography;
const { confirm } = Modal;

const CommentItem = forwardRef(({ item, onRemoveComment, onChangeCommentReaction }, ref) => {
  const navigate = useNavigate();
  const { user, openLoginModal } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirm = (id) => {
    if (isSubmitting) {
      return;
    }

    return confirm({
      title: "Confirm delete comment",
      content: "Are you sure you want to delete this comment?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onDeteleComment(id);
      },
    });
  };

  const onDeteleComment = async (id) => {
    try {
      setIsSubmitting(true);
      const response = await deleteExplorerComment(id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Deleted comment successfully!");
        onRemoveComment(id);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCommentReaction = (action, commentId, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (isSubmitting) {
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(commentId, reactionType);
    } else {
      doRemoveCommentReaction(commentId, reactionType);
    }
  };

  const doAddCommentReaction = async (commentId, reactionType) => {
    const payload = {
      comment_id: commentId,
      reaction_type: reactionType,
    };

    try {
      setIsSubmitting(true);
      const { error } = await addExplorerCommentReaction(payload);
      if (error) {
        message.error(error.message);
      } else {
        onChangeCommentReaction(commentId, reactionType, ADD_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const doRemoveCommentReaction = async (commentId, reactionType) => {
    try {
      setIsSubmitting(true);
      const { error } = await removeExplorerCommentReaction(reactionType, commentId);
      if (error) {
        message.error(error);
      } else {
        onChangeCommentReaction(commentId, reactionType, REMOVE_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex ref={ref} gap={12} style={{ padding: "6px 0" }}>
      <div>
        <Avatar
          style={{ cursor: "pointer" }}
          size="large"
          src={item.user_avatar}
          onClick={() => navigate(`/profile/${item.user_id}`)}
        />
      </div>

      <Flex vertical style={{ flexGrow: 1 }}>
        <Flex justify="space-between" align="center">
          <Flex align="center" gap={12}>
            <span style={{ fontWeight: "700", fontSize: "0.9rem" }}>{item.user_name}</span>
            <span style={{ fontWeight: "normal", fontSize: "0.7rem", color: "#606060", marginBottom: "-2px" }}>
              {item.created_at}
            </span>
          </Flex>

          {user && user.user_id === item.user_id && (
            <Button size="small" onClick={() => showDeleteConfirm(item.id)} shape="circle" icon={<DeleteOutlined />} />
          )}
        </Flex>

        <Flex vertical>
          <Paragraph style={{ marginBottom: "3px", fontSize: "0.8rem" }} ellipsis={{ rows: 4, expandable: true }}>
            {item.comment}
          </Paragraph>

          <Space>
            <Button
              style={{ border: "none" }}
              onClick={() =>
                item.active_for_user_heart
                  ? handleCommentReaction("REMOVE_REACTION", item.id, HEART_REACTION)
                  : handleCommentReaction("ADD_REACTION", item.id, HEART_REACTION)
              }
              icon={item.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}>
              {item.hearts_count}
            </Button>
            <Button
              style={{ border: "none" }}
              onClick={() =>
                item.active_for_user_like
                  ? handleCommentReaction("REMOVE_REACTION", item.id, LIKE_REACTION)
                  : handleCommentReaction("ADD_REACTION", item.id, LIKE_REACTION)
              }
              icon={item.active_for_user_like ? <LikeFilled style={{ color: "#1677ff" }} /> : <LikeOutlined />}>
              {item.likes_count}
            </Button>
          </Space>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default CommentItem;
