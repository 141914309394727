import "./CreatePost.css";
import { useState } from "react";
import { Flex, Avatar, Button, Space, Input, Row, Col, Divider, message, Radio } from "antd";
import { CloseOutlined, CloseCircleOutlined, BlockOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/AuthContext";
import { createPost, uploadToSignedURL, getPostImagesUploadKey, updatePost } from "../../../../services/supabaseClient";
import { public_image_url } from "../../../../utils/service";
import useWindowSize from "../../../../hooks/useWindowSize";
import SearchRelatePerfume from "./SearchRelatePerfume/SearchRelatePerfume";
import ImageDragger from "./ImageDragger/ImageDragger";
import VideoDragger from "./VideoDragger/VideoDragger";

const { TextArea } = Input;
const typeMedias = {
  image: "image",
  video: "video",
};

const CreatePost = ({ onClose, onCreate }) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();

  const [valueCaption, setValueCaption] = useState(null);
  const [typeMedia, setTypeMedia] = useState(typeMedias.image);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onCancel = () => {
    resetForm();
  };

  const onSubmit = async () => {
    const { isValid, error } = onValidate();
    if (!isValid) {
      message.warning(error);
      return;
    }

    setIsSubmiting(true);
    let perfumes = [];
    if (selectedPerfumes.length > 0) {
      perfumes = selectedPerfumes.map((item) => item.id);
    }

    const payload = {
      caption: valueCaption.trim(),
      media_data: null,
      perfumes,
    };

    try {
      const res = await createPost(payload);

      if (res.error) {
        message.error(res.error.message);
      } else {
        const newPostId = res.data[0].id;

        const urlList = await handleUploadPostImages(newPostId, imageList);
        if (!urlList) {
          message.error("Error uploading image, please try again!");
          setIsSubmiting(false);
          return;
        }

        const payloadUpdate = {
          media_data: { type: "image", urls: urlList },
        };

        const res1 = await updatePost(newPostId, payloadUpdate);
        if (res1.error) {
          message.error(res1.error.message);
        } else {
          message.success("Post was created successfully");
          resetForm();

          setTimeout(() => {
            onCreate();
          }, 1000);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onValidate = () => {
    if (!valueCaption) {
      return {
        idValid: false,
        error: "Caption is required!",
      };
    }

    if (!imageList) {
      return {
        idValid: false,
        error: "Image is required!",
      };
    }

    return {
      isValid: true,
      error: null,
    };
  };

  const handleUploadPostImages = async (id, files) => {
    return new Promise(async (resolve, _reject) => {
      try {
        const { error, data } = await getPostImagesUploadKey(id, files.length);

        if (error) {
          message.error(error.message);
          resolve(null);
        } else {
          const urlList = [];

          for (let i = 0; i < data.length; i++) {
            const { path, token } = data[i];
            const res = await uploadToSignedURL("posts", path, token, files[i].originFileObj);

            if (res.error) {
              message.error(res.error.message);
              resolve(null);
            } else {
              const { fullPath } = res.data;
              urlList.push(`${public_image_url}/${fullPath}`);
            }
          }

          resolve(urlList);
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const resetForm = () => {
    setValueCaption(null);
    setTypeMedia(typeMedias.image);
    setImageList([]);
    setSelectedPerfumes([]);
    setHasRelatePerfumes(false);
  };

  //handle upload image
  const [imageList, setImageList] = useState([]);

  const onImageChange = (fileList) => {
    setImageList(fileList);
  };

  //handle relate perfumes
  const [selectedPerfumes, setSelectedPerfumes] = useState([]);
  const [hasRelatePerfumes, setHasRelatePerfumes] = useState(false);

  const handleRemoveSelectPerfume = (item) => {
    const newSelectedPerfumes = selectedPerfumes.filter((perfume) => perfume.id !== item.id);
    setSelectedPerfumes(newSelectedPerfumes);
  };

  const handleSearchPerfumeAdd = (perfumes) => {
    if (selectedPerfumes && selectedPerfumes.length > 0) {
      const newSelectedPerfumes = [...selectedPerfumes];
      perfumes.forEach((item) => {
        const isExisted = selectedPerfumes.find((perfume) => perfume.id === item.id);
        if (!isExisted) {
          newSelectedPerfumes.push(item);
        }
      });

      setSelectedPerfumes(newSelectedPerfumes);
    } else {
      setSelectedPerfumes(perfumes);
    }
  };

  const handleCloseRelatePerfumes = () => {
    setSelectedPerfumes([]);
    setHasRelatePerfumes(false);
  };

  return (
    <Flex justify="center">
      {user && (
        <div className="vfra-create-post-container">
          <Flex justify="space-between" align="center">
            <Flex justify="center" align="center" gap={12}>
              <Avatar alt="" src={user.vfra_user?.avatar ?? user.avatar_url} />
              <span style={{ fontWeight: "bold" }}>{user.vfra_user?.name ?? user.name}</span>
            </Flex>
            <span style={{ color: "#606060", fontSize: "0.8rem" }}>Visibility: Public</span>
          </Flex>

          <Radio.Group
            onChange={(e) => setTypeMedia(e.target.value)}
            value={typeMedia}
            defaultValue="image"
            style={{ margin: "16px 0" }}>
            <Radio.Button size={isMobileView ? "small" : "default"} value={typeMedias.image}>
              Image
            </Radio.Button>
            <Radio.Button size={isMobileView ? "small" : "default"} value={typeMedias.video}>
              Video
            </Radio.Button>
          </Radio.Group>

          {typeMedia === typeMedias.image && <ImageDragger imageList={imageList} onImageChange={onImageChange} />}

          {typeMedia === typeMedias.video && <VideoDragger onImageChange={onImageChange} />}

          <TextArea
            style={{ marginTop: "30px", marginBottom: "12px" }}
            value={valueCaption}
            onChange={(e) => setValueCaption(e.target.value)}
            placeholder="Write a caption here..."
            autoSize={{
              minRows: 1,
            }}
            maxLength={300}
            variant="borderless"
          />

          {hasRelatePerfumes && (
            <Flex vertical style={{ marginBottom: "12px", position: "relative" }}>
              <Divider style={{ position: "relative", fontSize: "0.9rem" }}>
                Relate perfumers
                <Button
                  onClick={handleCloseRelatePerfumes}
                  size="small"
                  shape="circle"
                  className="vfra-close-relate-perfumes-btn"
                  icon={<CloseOutlined />}
                />
              </Divider>

              <SearchRelatePerfume onAdd={handleSearchPerfumeAdd} />

              {selectedPerfumes.length > 0 && (
                <Row gutter={[12, 12]} style={{ maxHeight: "300px", overflow: "scroll", paddingTop: "18px" }}>
                  {selectedPerfumes.map((item) => (
                    <Col span={8} key={item.id}>
                      <Flex
                        justify="start"
                        align="center"
                        className="vfra-selected-perfume-item"
                        style={{ position: "relative" }}>
                        <img alt="" width="25px" src={item.image_url} style={{ marginRight: "6px" }} />
                        <Flex vertical>
                          <span style={{ fontWeight: "700", fontSize: "0.9" }} className="one-line-ellipsis">
                            {item.name}
                          </span>
                          <span className="one-line-ellipsis" style={{ fontSize: "0.7rem" }}>
                            {item.brand_name}
                          </span>
                        </Flex>

                        <Button
                          onClick={() => handleRemoveSelectPerfume(item)}
                          size="small"
                          type="text"
                          className="vfra-remove-selected-perfume-btn"
                          shape="circle"
                          icon={<CloseCircleOutlined />}
                        />
                      </Flex>
                    </Col>
                  ))}
                </Row>
              )}
            </Flex>
          )}

          <Flex justify={`${!hasRelatePerfumes ? "space-between" : "end"}`} gap={6} style={{ marginTop: "12px" }}>
            {!hasRelatePerfumes && (
              <Button
                style={{ marginLeft: "-16px" }}
                onClick={() => setHasRelatePerfumes(true)}
                size={isMobileView ? "small" : "default"}
                shape="round"
                type="text"
                icon={<BlockOutlined />}>
                Relate perfumes
              </Button>
            )}

            <Space size={6}>
              <Button
                size={isMobileView ? "small" : "default"}
                disabled={isSubmiting}
                onClick={onClose}
                shape="round"
                type="text">
                Close
              </Button>

              {(valueCaption || imageList.length > 0 || selectedPerfumes.length > 0) && (
                <Button
                  size={isMobileView ? "small" : "default"}
                  disabled={isSubmiting}
                  onClick={onCancel}
                  shape="round"
                  type="text">
                  Cancel
                </Button>
              )}

              <Button
                size={isMobileView ? "small" : "default"}
                loading={isSubmiting}
                onClick={onSubmit}
                disabled={!valueCaption || imageList.length === 0}
                shape="round">
                Post
              </Button>
            </Space>
          </Flex>
        </div>
      )}
    </Flex>
  );
};
export default CreatePost;
