import { Flex, Divider, Row, Col, Button } from "antd";
import { formatNumber } from "../../../utils/service";
import Perfume from "./Perfume";
import useWindowSize from "../../../hooks/useWindowSize";

const SearchPerfumes = ({ totalPerfumes, perfumes, loading, onLoadMore }) => {
  const { isMobileView } = useWindowSize();
  const formatedTotal = formatNumber(totalPerfumes);
  return (
    <>
      <Divider style={{ marginBottom: "1.5rem" }}>Perfume search results ({formatedTotal})</Divider>
      <Row gutter={!isMobileView ? [32, 32] : [24, 24]} justify="center" vertical="vertical">
        {perfumes.map((item) => {
          return (
            <Col key={item.id} span={!isMobileView ? 6 : 24}>
              <Perfume item={item} />
            </Col>
          );
        })}
      </Row>
      {perfumes.length < totalPerfumes && (
        <Flex justify="center" style={{ marginTop: "24px" }}>
          <Button onClick={onLoadMore} loading={loading} iconPosition="end">
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
};

export default SearchPerfumes;
