import "./Profile.css";
import { Col, Row, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { ShoppingOutlined, MessageOutlined, AppstoreOutlined, GlobalOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import useWindowSize from "../../hooks/useWindowSize";
import UserInfo from "./UserInfo/UserInfo";
import OwnPerfumes from "./OwnPerfumes/OwnPerfumes";
import Reviews from "./Reviews/Reviews";
import Favorites from "./Favorites/Favorites";
import Community from "./Community/Community";

const menu1 = [
  {
    key: "0",
    label: `Community`,
    children: <Community />,
    icon: <GlobalOutlined />,
  },
  {
    key: "1",
    label: `Overall`,
    children: <Favorites />,
    icon: <ShoppingOutlined />,
  },
  {
    key: "2",
    label: `Reviews`,
    children: <Reviews />,
    icon: <MessageOutlined />,
  },
];

const menu2 = [
  {
    key: "0",
    label: `Community`,
    children: <Community />,
    icon: <GlobalOutlined />,
  },
  {
    key: "1",
    label: `Overall`,
    children: <Favorites />,
    icon: <ShoppingOutlined />,
  },
  {
    key: "2",
    label: `Reviews`,
    children: <Reviews />,
    icon: <MessageOutlined />,
  },
  {
    key: "3",
    label: `Perfumes`,
    children: <OwnPerfumes />,
    icon: <AppstoreOutlined />,
  },
];

const Profile = () => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  return (
    <Row gutter={[0, 16]} className="vfra-profile vfra-padding-top">
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <UserInfo />
      </Col>
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <Tabs centered defaultActiveKey="0" items={isYourself ? menu2 : menu1} />
      </Col>
    </Row>
  );
};

export default Profile;
