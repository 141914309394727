import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: "https://search-service.perfumerates.com/",
  apiKey: "a0fbf762eab47855962447266362c6c25273f04623cfdf9700830af950bde20b",
});

export const search_brands = (valueSearch, page) => {
  const pageSize = 20;
  const index = client.index("vfra_brands");
  return index.search(valueSearch, {
    limit: pageSize,
    offset: (page - 1) * pageSize,
  });
};

export default client;
