import imageCompression from "browser-image-compression";

export const MOBILE_BREAKPOINT = 768;

export const formatNumber = (number) => number.toLocaleString("en-US");

export const formatISOToUTC7 = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();
  const hours = String(utc7Date.getUTCHours()).padStart(2, "0");
  const minutes = String(utc7Date.getUTCMinutes()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
};

export const formatISOToUTC7Date = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  return new Date(date.getTime() + offset);
};

export const countReactions = (data, type) => {
  const reaction = data.find((e) => e.reaction_type === type);
  return {
    reaction_count: reaction ? reaction.reaction_count : 0,
    active_for_user: reaction ? reaction.active_for_user : false,
  };
};

export const classifyDataByName = (data, field_name) => {
  const classified = Array.from({ length: 26 }, (_, index) => {
    return {
      alphaCode: String.fromCharCode(index + 65),
      designers: [],
    };
  });

  data.forEach((item) => {
    const firstLetter = item[`${field_name}`].charAt(0).toUpperCase();

    // Calculate the index in the classified array (A=0, B=1, ..., Z=25)
    const index = firstLetter.charCodeAt(0) - 65;

    if (index >= 0 && index < 26) {
      classified[index].designers.push(item);
    }
  });

  return classified;
};

export const HEART_REACTION = "heart";
export const LIKE_REACTION = "like";
export const ADD_REACTION_ACTION = "add";
export const REMOVE_REACTION_ACTION = "remove";

export const prevLoadImage = (data, field_name) => {
  if (data.length > 0) {
    data.forEach((item) => {
      const img = new Image();
      img.src = item[field_name];
    });
  }
};

//============= Votes =================

export const getTotalFromObject = (dataObject) => {
  if (!dataObject) return 0;

  let total = 0;
  for (const key in dataObject) {
    total += dataObject[key];
  }

  return total;
};

export const getMaxValueFromObject = (dataObject) => {
  if (!dataObject) return 0;

  let max = 0;
  for (const key in dataObject) {
    if (dataObject[key] > max) max = dataObject[key];
  }

  return max;
};

//============= Accords =================
export const getMaxValueFromList = (list, fieldName) => {
  if (list.length === 0) return 0;

  let max = 0;
  list.forEach((e) => {
    if (e[fieldName] > max) max = e[fieldName];
  });
  return max;
};

//============= Upload helper function =================
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const imageConverterToJpg = (file, maxSizeMB = 2) =>
  new Promise(async (resolve, reject) => {
    if (file.type === "image/jpeg" || file.type === "image/jpg") {
      resolve({
        data: file,
      });
    }

    const options = {
      maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try {
      const compressedFile = await imageCompression(file, options);
      resolve({
        data: compressedFile,
      });
    } catch (error) {
      reject({
        error: error,
      });
    }
  });

export const public_image_url = "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public";
