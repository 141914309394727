import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Empty, Flex, message, Spin, Row, Col, Button } from "antd";
import { getPosts } from "../../../../../services/supabaseClient";
import { useAuth } from "../../../../../contexts/AuthContext";
import useWindowSize from "../../../../../hooks/useWindowSize";
import PublishedItem from "./PublishedItem/PublishedItem";
const PAGE_SIZE = 8;

export const Published = ({ reload }) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showLoadmore, setShowLoadmore] = useState(true);

  const fetchData = useCallback(
    async (startTime = 0) => {
      try {
        setLoading(true);
        const { error, data } = await getPosts(userId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            if (startTime !== 0) {
              setPosts((prev) => [...prev, ...data]);
            } else {
              setPosts(data);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, reload, userId]);

  const handlePostChange = () => {
    fetchData();
  };

  const handleLoadmore = () => {
    const startTime = posts.length > 0 ? posts[posts.length - 1].post.created_at : 0;
    fetchData(startTime);
  };

  const rowGutter = isMobileView ? [12, 12] : [36, 36];
  const itemSpan = isMobileView ? 12 : 6;

  return (
    <>
      {!loading && posts.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Publish post empty</p>
              {isYourself && (
                <span style={{ fontSize: "0.8rem", color: "#606060" }}>
                  Posts appear here after you publish and will be visible to your community
                </span>
              )}
            </Flex>
          }
        />
      )}

      {posts.length > 0 && (
        <>
          <Flex style={{ marginTop: isMobileView ? "6pz" : "12px", fontWeight: "bold" }} justify="space-between">
            <span>List publish</span>
          </Flex>

          <Row gutter={rowGutter} justify="start" style={{ marginTop: isMobileView ? "6px" : "12px" }}>
            {posts.map((post) => (
              <Col span={itemSpan} key={post.post.id}>
                <PublishedItem post={post.post} onPostChange={handlePostChange} />
              </Col>
            ))}
          </Row>
        </>
      )}

      {loading && (
        <Flex justify="center" style={{ marginTop: "12px" }}>
          <Spin />
        </Flex>
      )}

      {showLoadmore && (
        <Flex justify="center" style={{ marginBottom: "12px", marginTop: "18px" }}>
          <Button onClick={handleLoadmore} disabled={loading} size="small">
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
};

export default Published;
