import { useNavigate } from "react-router-dom";
import { Card, Flex, Badge } from "antd";

const cardStyle = {
  margin: "16px",
  height: "70px",
};

const BrandItem = ({ designer }) => {
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      key={designer.id}
      style={cardStyle}
      bordered={false}
      onClick={() => navigate(`/brand/${designer.id}`)}
      styles={{
        body: {
          padding: "8px",
        },
      }}>
      <Flex justify="start" align="center">
        <img alt="" width="50px" src={designer.logo_url} style={{ marginRight: "12px" }} />
        <span style={{ fontWeight: "700" }}>{designer.brand_name}</span>
        <div style={{ marginLeft: "auto" }}>
          <Badge
            count={designer.perfume_count}
            style={{
              backgroundColor: "rgb(229 229 229 / 37%)",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "700",
            }}
          />
        </div>
      </Flex>
    </Card>
  );
};

export default BrandItem;
