import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import "./antd-overrides.css";
import { useAuth } from "./contexts/AuthContext";
import MainLayout from "./layouts/MainLayout/MainLayout";
import NoneFooterLayout from "./layouts/NoneFooterLayout/NoneFooterLayout";
import Home from "./pages/Home/Home";
import Perfumes from "./pages/Perfumes/Perfumes";
import Brands from "./pages/Brands/Brands";
import BrandDetails from "./pages/Brands/BrandDetails/BrandDetails";
import Products from "./pages/Products/Products";
import LoginModal from "./components/LoginModal";
import Profile from "./pages/Profile/Profile";

function App() {
  const { showLoginModal, closeLoginModal } = useAuth();

  const location = useLocation();
  useEffect(() => {
    // Check if the current route is the one where you want to prevent scrolling
    if (location.pathname === "/home") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup to reset body overflow when the component unmounts or route changes
    return () => {
      document.body.style.overflow = "";
    };
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route
            path="perfume"
            element={
              <>
                <Helmet>
                  <title>Perfumes - Vfra</title>
                </Helmet>
                <Perfumes />
              </>
            }
          />
          <Route
            path="product/:productId"
            element={
              <>
                <Helmet>
                  <title>Perfume details - Vfra</title>
                </Helmet>
                <Products />
              </>
            }
          />
          <Route
            path="brand"
            element={
              <>
                <Helmet>
                  <title>Designers - Vfra</title>
                </Helmet>
                <Brands />
              </>
            }
          />
          <Route
            path="brand/:brandId"
            element={
              <>
                <Helmet>
                  <title>Designer details - Vfra</title>
                </Helmet>
                <BrandDetails />
              </>
            }
          />
          <Route
            path="profile/:userId"
            element={
              <>
                <Helmet>
                  <title>Profile - Vfra</title>
                </Helmet>
                <Profile />
              </>
            }
          />
        </Route>

        <Route path="/" element={<NoneFooterLayout />}>
          <Route
            path="home/:postId?"
            element={
              <>
                <Helmet>
                  <title>Home - Vfra</title>
                </Helmet>
                <Home />
              </>
            }></Route>
        </Route>
      </Routes>
      <LoginModal visible={showLoginModal} onCancel={closeLoginModal} />
    </>
  );
}

export default App;
