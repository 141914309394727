import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { Divider, Radio, Flex, message } from "antd";
import ThreePartsProgress from "../../../../components/ThreePartsProgress/ThreePartsProgress";
import { getTotalFromObject } from "../../../../utils/service";
import { addVotes } from "../../../../services/supabaseClient";
import useWindowSize from "../../../../hooks/useWindowSize";

const radioGroupStyle = { width: "100%", marginBottom: "12px" };
const radioStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  color: "rgb(100 100 100)",
};

const GenderVote = ({ data, userVote }) => {
  const { isMobileView } = useWindowSize();
  const { productId } = useParams();
  const { user } = useAuth();

  const [female, setFemale] = useState(null);
  const [unisex, setUnisex] = useState(null);
  const [male, setMale] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const [valueVote, setValueVote] = useState(null);

  const setInitValues = (dataInit, initUserVote) => {
    setDataVote(dataInit);
    const total = getTotalFromObject(dataInit);

    const initFemale = dataInit?.female ? (dataInit.female / total) * 100 : 0;
    setFemale(initFemale);

    const initUnisex = dataInit?.unisex ? (dataInit.unisex / total) * 100 : 0;
    setUnisex(initUnisex);

    const initMale = dataInit?.male ? (dataInit.male / total) * 100 : 0;
    setMale(initMale);

    if (initUserVote && initUserVote.genders) {
      setValueVote(initUserVote.genders);
    } else {
      setValueVote(null);
    }
  };

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote]);

  const updateDataVote = (oldValueVote, newValueVote, newDataUserVotes) => {
    const newDataVotes = {
      ...dataVote,
      [newValueVote]: dataVote[newValueVote] + 1,
    };

    if (oldValueVote && dataVote[oldValueVote] > 0) {
      newDataVotes[oldValueVote] = dataVote[oldValueVote] - 1;
    }

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onChange = async (e) => {
    const { value } = e.target;
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        const votes = {
          genders: {
            [value]: true,
          },
        };

        const { error } = await addVotes(productId, votes);

        if (error) {
          message.error(error.message);
        } else {
          message.success("Your vote successfully saved!");
          setValueVote(value);

          const newDataUserVotes = {
            genders: value,
          };

          updateDataVote(valueVote, value, newDataUserVotes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const progressHeight = !isMobileView ? 40 : 20;

  return (
    <>
      <Divider>
        <span style={{ fontSize: "14px" }}>Genders</span>
      </Divider>
      <Radio.Group style={radioGroupStyle} onChange={onChange} value={valueVote}>
        <Flex justify="space-between">
          <Radio value="female" style={radioStyle}>
            female
          </Radio>
          <Radio value="unisex" style={radioStyle}>
            unisex
          </Radio>
          <Radio value="male" style={radioStyle}>
            male
          </Radio>
        </Flex>
      </Radio.Group>
      <ThreePartsProgress part1={female} part2={unisex} part3={male} height={progressHeight} />
    </>
  );
};

export default GenderVote;
