import "./VerticalProgress.css";

const colorFull = "#52c41a";

const VerticalProgressBar = ({ percent, height = 200, width = 20, color = "#1677ff", showText = true }) => {
  return (
    <div className="vertical-progress-bar-container" style={{ height: `${height}px`, width: `${width}px` }}>
      <div className="vertical-progress-bar">
        <div
          className="vertical-progress-bar-fill"
          style={{
            height: `${percent}%`,
            backgroundColor: percent === 100 ? colorFull : color,
          }}>
          {showText && <span className="vertical-progress-bar-text">{percent}%</span>}
        </div>
      </div>
    </div>
  );
};

export default VerticalProgressBar;
