import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: "https://search-service.perfumerates.com/",
  apiKey: "8dbd8ad6724b9295f7c4f43499f369d75c19afdbcb4258a682d41accf4dbba23",
});

export const search_perfumes = (valueSearch, page) => {
  const pageSize = 20;
  const index = client.index("vfra_perfumes");
  return index.search(valueSearch, {
    limit: pageSize,
    offset: (page - 1) * pageSize,
  });
};

export const search_perfumes_by_brand_id = (valueSearch, page, brandId) => {
  const pageSize = 10;
  const index = client.index("vfra_perfumes");
  return index.search(valueSearch, {
    filter: [`brand_id = ${brandId.trim()}`],
    limit: pageSize,
    offset: (page - 1) * pageSize,
  });
};

export default client;
