import { Tabs } from "antd";
import Published from "./Published/Published";

const ManagePost = ({ reloadPosts }) => {
  const menu = [
    {
      key: "0",
      label: `Published`,
      children: <Published reload={reloadPosts} />,
    },
  ];

  return <Tabs centered defaultActiveKey="0" items={menu} />;
};

export default ManagePost;
